import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaWhatsapp, FaInstagram, FaUsers } from 'react-icons/fa'; // Importando FaUsers
import logo from './IMG/logo.png';  
import banner from './IMG/banner.png';
import banner3 from './IMG/banner3.png';
import banner4 from './IMG/banner4.png';
import banner5 from './IMG/banner5.png';
import './App.css';  
import { FaLock, FaBox, FaStore } from 'react-icons/fa'; 

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleClick = () => {
    window.open("https://wa.me/5538988605636", "_blank");
  };

  return (
    <div className="AppContainer">
      <header className="Header">
        <img src={logo} alt="Logo CodeX" className="Logo" />
      </header>

      <div className="Banner">
        <img src={banner} alt="Banner CodeX" className="BannerImage" />
      </div>

      <section className="HorizontalSection" data-aos="fade-right">
        <div className="SectionContent">
          <h2 className="SectionTitle" data-aos="fade-right">
            <FaLock className="Icon" /> Loja Virtual
          </h2>
          <p className="SectionText" data-aos="fade-right">
            <strong>Vendas seguras</strong> via Pix ou Cartão de Crédito. Ofereça uma experiência de compra fácil e intuitiva.
          </p>
        </div>
      </section>

      <section className="HorizontalSection" data-aos="fade-left">
        <div className="SectionContent">
          <h2 className="SectionTitle" data-aos="fade-left">
            <FaBox className="Icon" /> Administração de Produtos
          </h2>
          <p className="SectionText" data-aos="fade-left">
            Gerencie seus produtos com facilidade: adicione, edite e apague produtos, controle <strong>estoque, faturamento</strong> e <strong>pedidos</strong>.
          </p>
        </div>
      </section>

      <section className="HorizontalSection" data-aos="fade-right">
        <div className="SectionContent">
          <h2 className="SectionTitle" data-aos="fade-right">
            <FaStore className="Icon" /> Vendas Físicas
          </h2>
          <p className="SectionText" data-aos="fade-right">
            Registre suas vendas físicas e veja o <strong>estoque atualizado automaticamente</strong>. Integração total com sua loja física.
          </p>
        </div>
      </section>
      
      {/* Novo Título: Alguns de nossos clientes */}
      <section className="ClientSection" data-aos="fade-up">
        <h2 className="ClientTitle">
          <FaUsers className="Icon" /> Alguns de nossos clientes
        </h2>
        <img src={banner3} alt="Cliente Banner 3" className="ClientBanner" data-aos="fade-up" />
        <img src={banner4} alt="Cliente Banner 4" className="ClientBanner" data-aos="fade-up" />
        <img src={banner5} alt="Cliente Banner 5" className="ClientBanner" data-aos="fade-up" />
      </section>

      <button className="Button" data-aos="fade-up" onClick={handleClick}>
        Entre em contato
      </button>

      <div className="IconContainer">
        <a href="https://wa.me/5538988605636" target="_blank" rel="noopener noreferrer" className="IconLink">
          <FaWhatsapp />
        </a>
        <a href="https://www.instagram.com/codex.corp/" target="_blank" rel="noopener noreferrer" className="IconLink">
          <FaInstagram />
        </a>
      </div>

      <footer className="Footer">
        <p>© 2024 CodeX - Especialista em Lojas Virtuais</p>
      </footer>
    </div>
  );
}

export default App;
